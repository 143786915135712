import React from 'react';

import Layout from '../components/layout';

const HomeIndex = () => {
    return (
        <Layout>
            <section className="section-50 section-md-75 section-xl-115 bg-transparent">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-xxl-9">
                            <dl className="list-terms">
                                <dt className="h4">General information</dt>
                                <dd>
                                    Welcome to our Privacy Policy page! When you use our web site services, you trust us
                                    with your
                                    information. This Privacy Policy is meant to help you understand what data we
                                    collect, why we
                                    collect it, and what we do with it. When you share information with us, we can make
                                    our services
                                    even better for you. For instance, we can show you more relevant search results and
                                    ads, help
                                    you connect with people or to make sharing with others quicker and easier. As you
                                    use our
                                    services, we want you to be clear how we’re using information and the ways in which
                                    you can
                                    protect your privacy. This is important; we hope you will take time to read it
                                    carefully.
                                    Remember, you can find controls to manage your information and protect your privacy
                                    and
                                    security. We’ve tried
                                    to keep it as simple as possible.
                                </dd>
                                <dt className="h4">Right to access, correct and delete data and to object to data
                                    processing
                                </dt>
                                <dd>
                                    Our customers have the right to access, correct and delete personal data relating to
                                    them, and
                                    to object to the processing of such data, by addressing a written request, at any
                                    time. The
                                    Company makes every effort to put in place suitable precautions to safeguard the
                                    security and
                                    privacy of personal data, and to prevent it from being altered, corrupted, destroyed
                                    or accessed
                                    by unauthorized third parties. However, the Company does not control each and every
                                    risk related
                                    to the use of the Internet, and therefore warns the Site users of the potential
                                    risks involved
                                    in the functioning and use of the Internet. The Site may include links to other web
                                    sites or
                                    other internet sources. As the Company cannot control these web sites and external
                                    sources, the
                                    Company cannot be held responsible for the provision or display of these web sites
                                    and external
                                    sources, and may not be held liable for the content, advertising, products, services
                                    or any
                                    other material available on or from these web sites or external sources.
                                </dd>
                                <dt className="h4">Management of personal data</dt>
                                <dd>
                                    You can view or edit your personal data online for many of our services. You can
                                    also make
                                    choices about our collection and use of your data. How you can access or control
                                    your personal
                                    data will depend on which services you use. You can choose whether you wish to
                                    receive
                                    promotional communications from our web site by email, SMS, physical mail, and
                                    telephone. If you
                                    receive promotional email or SMS messages from us and would like to opt out, you can
                                    do so by
                                    following the directions in that message. You can also make choices about the
                                    receipt of
                                    promotional email, telephone calls, and postal mail by visiting and signing into
                                    Company
                                    Promotional Communications Manager, which allows you to update contact information,
                                    manage
                                    contact preferences, opt out of email subscriptions, and choose whether to share
                                    your contact
                                    information with our partners. These choices do not apply to mandatory service
                                    communications
                                    that are part of certain web site services.
                                </dd>
                                <dt className="h4">Information We Collect</dt>
                                <dd>
                                    Our store collects data to operate effectively and provide you the best experiences
                                    with our
                                    services. You provide some of this data directly, such as when you create a personal
                                    account.
                                    We get some of it by recording how you interact with our services by, for example,
                                    using
                                    technologies like cookies, and receiving error reports or usage data from software
                                    running on
                                    your device. We also obtain data from third parties (including other companies). For
                                    example, we
                                    supplement the data we collect by purchasing demographic data from other companies.
                                    We also use
                                    services from other companies to help us determine a location based on your IP
                                    address in order
                                    to customize certain services to your location. The data we collect depends on the
                                    services
                                    and features you use.
                                </dd>
                                <dt className="h4">How We Use Your Information</dt>
                                <dd>
                                    Our web site uses the data we collect for three basic purposes: to operate our
                                    business and
                                    provide (including improving and personalizing) the services we offer, to send
                                    communications,
                                    including promotional communications, and to display advertising. In carrying out
                                    these
                                    purposes, we combine data we collect through the various web site services you use
                                    to give you a
                                    more seamless, consistent and personalized experience. However, to enhance privacy,
                                    we have
                                    built in technological and procedural safeguards designed to prevent certain data
                                    combinations.
                                    For example, we store data we collect from you when you are unauthenticated (not
                                    signed in)
                                    separately from any account information that directly identifies you, such as your
                                    name, email
                                    address or phone number.
                                </dd>
                                <dt className="h4">Sharing Your Information</dt>
                                <dd>
                                    We share your personal data with your consent or as necessary to complete any
                                    transaction or
                                    provide any service you have requested or authorized. For example, we share your
                                    content with
                                    third parties when you tell us to do so. When you provide payment data to make a
                                    purchase, we
                                    will share payment data with banks and other entities that process payment
                                    transactions or
                                    provide other financial services, and for fraud prevention and credit risk
                                    reduction. In
                                    addition, we share personal data among our controlled affiliates and subsidiaries.
                                    We also share
                                    personal data with vendors or agents working on our behalf for the purposes
                                    described in this
                                    statement. For example, companies we've hired to provide customer service support or
                                    assist in
                                    protecting and securing our systems and services may need access to personal data in
                                    order to
                                    provide those functions. In such cases, these companies must abide by our data
                                    privacy and
                                    security requirements and are not allowed to use personal data they receive from us
                                    for any
                                    other purpose. We may also disclose personal data as part of a corporate transaction
                                    such as a
                                    merger
                                    or sale of assets.
                                </dd>
                            </dl>
                            <a className="d-inline-block link mt-4 mt-lg-5" href="mailto:#">privacy@demolink.org</a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default HomeIndex;
